import safe from './images/safe.svg';
import structure from './images/structure.svg';
import eco from './images/eco.svg';

export default {
  cartridge: [
    {
      id: 1,
      image: safe,
      title: 'material.safe.title',
      text: 'material.safe.text',
    },
    {
      id: 2,
      image: structure,
      title: 'material.structure.title',
      text: 'material.structure.text',
    },
    {
      id: 3,
      image: eco,
      title: 'material.eco.title',
      text: 'material.eco.text',
    },
  ],
};
