import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../../Products/ui/Toggle';
import { List, Block, Row, Title, Text } from './styled';

function FAQList({ questions }) {
  const [active, setActive] = useState(null);
  const handleClick = (index) => {
    setActive(active === index ? null : index);
  };

  return (
    <List>
      {questions.map(({ title, content }, questionIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Block key={questionIndex}>
          <Row>
            <Title>{title}</Title>
            <Toggle
              onClick={() => handleClick(questionIndex)}
              active={active === questionIndex}
            />
          </Row>
          <Text active={active === questionIndex}>
            {content.map((contentChunk) => contentChunk)}
          </Text>
        </Block>
      ))}
    </List>
  );
}

FAQList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    }).isRequired
  ).isRequired,
};

export default FAQList;
