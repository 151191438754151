import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from '../ui/common';
import { colors } from '../../../ui/common';

export const Section = styled.section`
  overflow: hidden;
  color: ${colors.color01};
  padding: 60px 0;
  ${media.phone`
    padding: 50px 0 110px;
  `}
  ${media.laptop`
    padding: 0 0 86px;
  `}
`;
export const Row = styled.div`
  margin: 0 -15px;
  ${media.laptop`
    display: flex;
  `}
`;
export const Col = styled.div`
  padding: 0 15px;
  ${media.laptop`
    width: 50%;
  `}
`;
export const Link = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 12px 0;
  color: ${colors.color01};
  border-bottom: 1px solid;
  display: inline-block;
`;
export const Image = styled(GatsbyImage)`
  margin-bottom: 50px;
`;
export const Info = styled.div`
  box-sizing: border-box;
  font-weight: 300;
  opacity: 0.9;

  h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 1.5;
    margin: 0 0 32px;
  }
  p {
    line-height: 1.75;
  }
  ${media.tablet`
    p {
      margin-bottom: 32px;
    }
  `}
  ${media.laptop`
    margin-top: -5px;
    padding-left: 32px;
    h4 {
      margin-top: 22px;
    }
  `}
`;
export const Block = styled.div`
  line-height: 1.82;
  margin-bottom: 28px;

  b {
    font-weight: 500;
    display: block;
  }
`;
export const List = styled.ul`
  padding: 0;
  margin: 30px 0;
  line-height: 1.624;
  h5 {
    font-weight: 300;
    font-size: 13px;
    margin: 0 0 4px;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
  }
  ${media.phone`
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
  `}
`;
export const Item = styled.li`
  white-space: pre-wrap;
  display: block;
  margin-bottom: 30px;
  ${media.phone`
     width: 50%;
      padding: 0 16px;
      box-sizing: border-box;
  `}
`;
