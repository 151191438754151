import React from 'react';

export default [
  {
    title: 'products.faq.1.title',
    content: [{ id: 'products.faq.1.text.1' }, { id: 'products.faq.1.text.2' }],
  },
  {
    title: 'products.faq.2.title',
    content: [
      {
        id: 'products.faq.2.text.1',
        values: {
          a: (chunks) => (
            // eslint-disable-next-line react/react-in-jsx-scope
            <a
              className="typical-inline-link"
              href="https://support.evapolar.com/hc/en-us/articles/360019002959-How-do-evaporative-coolers-work-"
            >
              {chunks}
            </a>
          ),
        },
      },
      { img: true },
    ],
  },
  {
    title: 'products.faq.3.title',
    content: [{ id: 'products.faq.3.text.1' }],
  },
  {
    title: 'products.faq.4.title',
    content: [
      {
        id: 'products.faq.4.text.1',
        values: {
          a: (chunks) => (
            <a
              className="typical-inline-link"
              href="https://support.evapolar.com/hc/en-us/articles/360017212840-Evapolar-optimal-working-conditions-"
            >
              {chunks}
            </a>
          ),
        },
      },
    ],
  },
  {
    title: 'products.faq.5.title',
    content: [{ id: 'products.faq.5.text.1' }],
  },
];
