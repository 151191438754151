export default {
  evaaroma: {
    specifications: {
      size: 'specifications.size.evaaroma',
      weight: 'specifications.weight.evaaroma',
    },
    description: [
      {
        title: 'description.evaaroma.title1',
        text: 'description.evaaroma.text1',
      },
      {
        title: 'description.evaaroma.title2',
        text: 'description.evaaroma.text2',
      },
      {
        title: 'description.evaaroma.title3',
        text: 'description.evaaroma.text3',
      },
      {
        text: 'description.evaaroma.text4',
      },
    ],
  },
  evabank: {
    specifications: {
      size: 'specifications.size.evabank',
      weight: 'specifications.weight.evabank',
      connectorType: 'specifications.connectorType.evabank',
      batteryCapacity: 'specifications.batteryCapacity.evabank',
      batteryComposition: 'specifications.batteryComposition.evabank',
    },
    description: [
      {
        title: 'description.evabank.title1',
        text: 'description.evabank.text1',
      },
      {
        title: 'description.evabank.title2',
        text: 'description.evabank.text2',
      },
      {
        title: 'description.evabank.title3',
        text: 'description.evabank.text3',
      },
      {
        title: 'description.evabank.title4',
        text: 'description.evabank.text4',
      },
    ],
  },
  evabottle: {
    specifications: {
      size: 'specifications.size.evabottle',
      weight: 'specifications.weight.evabottle',
    },
    description: [
      {
        title: 'description.evabottle.title1',
        text: 'description.evabottle.text1',
      },
      {
        title: 'description.evabottle.title2',
        text: 'description.evabottle.text2',
      },
      {
        title: 'description.evabottle.title3',
        text: 'description.evabottle.text3',
      },
      {
        title: 'description.evabottle.title4',
        text: 'description.evabottle.text4',
      },
    ],
  },
  evabag: {
    specifications: {
      size: 'specifications.size.evabag',
      weight: 'specifications.weight.evabag',
    },
    description: [
      {
        title: 'description.evabag.title1',
        text: 'description.evabag.text1',
      },
      {
        title: 'description.evabag.title2',
        text: 'description.evabag.text2',
      },
      {
        title: 'description.evabag.title3',
        text: 'description.evabag.text3',
      },
    ],
  },
  evachill: {
    specifications: {
      size: 'specifications.size.evachillCartridge',
      weight: 'specifications.weight.evachillCartridge',
    },
    cartridge: {
      title: 'description.evachillCartridge.title',
      text: 'description.evachillCartridge.text',
      link: 'description.evachillCartridge.link',
    },
  },
  evalight: {
    specifications: {
      size: 'specifications.size.evalightCartridge',
      weight: 'specifications.weight.evalightCartridge',
    },
    cartridge: {
      title: 'description.evalightCartridge.title',
      text: 'description.evalightCartridge.text',
      link: 'description.evalightCartridge.link',
    },
  },
  evalightPlus: {
    specifications: {
      size: 'specifications.size.evalightPlusCartridge',
      weight: 'specifications.weight.evalightPlusCartridge',
    },
    cartridge: {
      title: 'description.evalightPlusCartridge.title',
      text: 'description.evalightPlusCartridge.text',
      link: 'description.evalightPlusCartridge.link',
    },
  },
  evasmart: {
    specifications: {
      size: 'specifications.size.evasmartCartridge',
      weight: 'specifications.weight.evasmartCartridge',
    },
    cartridge: {
      title: 'description.evasmartCartridge.title',
      text: 'description.evasmartCartridge.text',
      link: 'description.evasmartCartridge.link',
    },
  },
};
