import styled from 'styled-components';
import { media } from '../ui/common';
import { colors } from '../../../ui/common';

export const Section = styled.section`
  padding: 70px 0 30px;
  background: #f7fbfb;
  margin: 40px 0;
  ${media.tablet`
    margin: 7px 0 70px;
  `}
  ${media.laptop`
    margin: 0 0 118px;
  `}
`;
export const Title = styled.h3`
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: ${colors.color05};
  margin: 0 0 58px;
  line-height: 1.5;
`;
export const List = styled.div`
  margin: 0;
  padding: 0;
  color: ${colors.color01};
  ${media.laptop`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  `}
`;
export const Block = styled.div`
  text-align: center;
  box-sizing: border-box;
  max-width: 260px;
  margin: 0 auto 55px;
  img {
    margin: 0 0 16px;
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.6;
    margin: 0 0 8px;
  }
  p {
    font-weight: 300;
    font-size: 13px;
    line-height: 1.7;
    margin: 0;
  }
  ${media.tablet`
    max-width: 500px;
    margin: 0 auto 45px;
 `}
  ${media.laptop`
    width: 25%;
    margin-bottom: 30px;
    padding: 12px 24px;
    img {
      margin: 0 0 42px;
    }
    h5 {
      padding: 0 8px;
      margin: 0 0 12px;
    }
  `}
`;
