import styled, { css } from 'styled-components';

import { media } from './common';

export const container = css`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  ${media.medium`
    max-width: 930px;
  `}
  ${media.laptop`
    max-width: 1140px;
  `}
`;

const Container = styled.div`
  ${container};
`;

export default Container;
