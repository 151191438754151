import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Container from '../Products/ui/Container';
import { Headline } from '../Products/ui/common';
import img from '../../images/product-page/how_it_work.jpg';
import FAQList from './List';
import { Section } from './styled';

function FAQ({ questions }) {
  return (
    <Section>
      <Container>
        <Headline>
          <FormattedMessage id="faq.headline" />
        </Headline>
        <FAQList
          questions={questions.map(({ title, content }) => ({
            title: <FormattedMessage id={title} />,
            content: content.map((chunk, i) =>
              chunk.img ? (
                // eslint-disable-next-line react/no-array-index-key
                <img src={img} alt="Evaporative cooling diagram" key={i} />
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <FormattedMessage id={chunk.id} values={chunk.values} key={i} />
              )
            ),
          }))}
        />
      </Container>
    </Section>
  );
}

FAQ.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.bool,
          id: PropTypes.string,
          // eslint-disable-next-line react/forbid-prop-types
          values: PropTypes.any,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
};

export default FAQ;
