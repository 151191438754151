import styled from 'styled-components';
import { media } from '../../Products/ui/common';
import { colors } from '../../../ui/common';

export const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const List = styled.div`
  white-space: pre-wrap;
  margin: 40px auto 0;
`;

export const Block = styled.div`
  padding: 4px 0;
  font-size: 16px;
  line-height: 1.85;
  border-bottom: 1px solid ${colors.color09};
  &:first-of-type {
    border-top: 1px solid ${colors.color09};
  }
  ${media.laptop`
      padding: 13px 0;
  `}
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  margin: 16px 0;
  color: ${colors.color06};
`;

export const Text = styled.div`
  max-height: ${(props) => (props.active ? '200vh' : 0)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 0.1s ease-in;
  overflow: hidden;
  color: ${colors.color01};
  line-height: 1.5;
  img {
    max-width: 100%;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
