import styled from 'styled-components';
import { media } from '../Products/ui/common';

// eslint-disable-next-line import/prefer-default-export
export const Section = styled.section`
  padding: 92px 0;
  background: #f7fbfb;
  ${media.tablet`
    padding: 120px 0;
  `}
  ${media.laptop`
    padding: 130px 0;
  `}
`;
