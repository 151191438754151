import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Container } from '../ui/common';
import { Section, Title, List, Block } from './styled';
import list from './data';

function Material({ type }) {
  return (
    <Section>
      <Container>
        <Title>
          <FormattedMessage id="material.title" />
        </Title>
        <List>
          {list[type].map((item) => (
            <Block key={item.id}>
              <img src={item.image} alt="material" />
              <FormattedMessage id={item.title} tagName="h5" />
              <FormattedMessage id={item.text} tagName="p" />
            </Block>
          ))}
        </List>
      </Container>
    </Section>
  );
}
export default Material;

Material.propTypes = {
  type: PropTypes.string,
};

Material.defaultProps = {
  type: 'cartridge',
};
