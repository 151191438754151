import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Container } from '../ui/common';
import {
  Section,
  Row,
  Col,
  Info,
  Block,
  List,
  Item,
  Image,
  Link,
} from './styled';
import data from './data';

const HREF_BY_TYPE = new Map([
  [
    'evasmart',
    'https://support.evapolar.com/hc/en-us/articles/115004429545-How-to-replace-the-evaSMART-cartridge',
  ],
  [
    'evalightPlus',
    'https://support.evapolar.com/hc/en-us/articles/360009007560-How-to-replace-the-evaLIGHT-plus-cartridge',
  ],
  [
    'evalight',
    'https://support.evapolar.com/hc/en-us/articles/115004201489-How-to-replace-the-evaLIGHT-cartridge',
  ],
  [
    'evachill',
    'https://support.evapolar.com/hc/en-us/articles/360001848039-How-to-replace-the-evaCHILL-cartridge',
  ],
]);

function Description({ images, type }) {
  const { specifications, description, cartridge } = data[type];

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <Image image={images[0]} alt="" />
          </Col>
          <Col>
            <Info>
              {description &&
                description.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Block key={index}>
                    {item.title && (
                      <FormattedMessage id={item.title} tagName="b" />
                    )}
                    <FormattedMessage id={item.text} />
                  </Block>
                ))}
              {cartridge && (
                <>
                  <FormattedMessage id={cartridge.title} tagName="h4" />
                  <FormattedMessage id={cartridge.text} tagName="p" />
                </>
              )}
              <List>
                {Object.keys(specifications).map((key, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Item key={index}>
                    <FormattedMessage
                      id={`specifications.${key}.title`}
                      tagName="h5"
                    />
                    <FormattedMessage id={specifications[key]} tagName="p" />
                  </Item>
                ))}
              </List>
              {cartridge && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link>
                  <FormattedMessage
                    id={cartridge.link}
                    values={{
                      // eslint-disable-next-line react/no-unstable-nested-components
                      a: (chunks) => (
                        <a href={HREF_BY_TYPE.get(type)}>{chunks}</a>
                      ),
                    }}
                  />
                </Link>
              )}
            </Info>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default Description;

Description.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string.isRequired,
};

Description.defaultProps = {
  images: [],
};
